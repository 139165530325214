import React, { FC, HTMLAttributes, useEffect, useState } from 'react';

import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundaries from './components/base/ErrorBoundaries';
import PlaygroundComponent from './components/PlaygroundComponent';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { store } from './store';
import LoadingSpinner from './components/shared/LoadingSpinner';
import LoadingContainer from './styles/LoadingContainer';

export interface PlaygroundProps extends HTMLAttributes<HTMLDivElement> {
  presets?: {
    url?: string;
    editorPresets?: {
      queryEditorValue?: string;
      responseEditorValue?: string;
    };
    settingsPresets?: {
      showUrl?: boolean;
      theme?: 'light' | 'dark' | 'hc-dark';
      showTabs?: boolean;
      showHeaders?: boolean;
      showSettings?: boolean;
    };
    showPlaygroundLink?: boolean;
  };
}

let persistor = persistStore(store);

const Playground: FC<PlaygroundProps> = ({ presets }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundaries>
          <PlaygroundComponent presets={presets} />
        </ErrorBoundaries>
      </PersistGate>
    </Provider>
  );
};

export default Playground;
