import React from 'react';
import styled from 'styled-components';

const CopyIconSVG = styled.svg`
  height: 20px;
  max-width: 20px;
  width: fit-content;
  g {
    fill: ${({ theme }) => theme.colors.textColor};
  }
`;

const CopyIcon = () => {
  return (
    <CopyIconSVG
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M1720 5104 c-155 -42 -269 -173 -293 -337 -10 -67 -9 -78 10 -113 26 -50 72 -77 129 -76 77 1 127 52 139 144 10 68 47 104 118 113 28 3 585 5 1237 3 1298 -3 1205 1 1248 -60 16 -21 17 -160 20 -1749 2 -1370 0 -1734 -10 -1765 -15 -45 -66 -84 -110 -84 -38 0 -104 -34 -119 -61 -37 -70 -29 -136 21 -184 25 -24 40 -30 93 -33 158 -10 314 95 379 255 l23 58 0 1790 c0 1710 -1 1792 -18 1843 -36 103 -132 202 -237 245 l-55 22 -1260 2 c-1086 2 -1268 0 -1315 -13z" />
        <path d="M785 4202 c-108 -37 -202 -129 -247 -239 l-23 -58 0 -1790 c0 -1710 1 -1792 18 -1843 36 -103 132 -202 237 -245 l55 -22 1254 -3 c1366 -3 1319 -4 1417 53 97 57 174 166 194 276 7 37 9 643 8 1815 l-3 1759 -23 58 c-46 113 -140 201 -256 240 -45 16 -162 17 -1315 16 -1167 0 -1270 -2 -1316 -17z m2564 -276 c15 -8 37 -28 47 -43 18 -27 19 -76 19 -1768 0 -1646 -1 -1742 -18 -1768 -9 -15 -29 -35 -44 -44 -26 -17 -98 -18 -1243 -18 -1315 0 -1259 -2 -1297 54 -17 25 -18 109 -21 1752 -2 1368 0 1735 10 1765 8 24 26 47 47 61 l34 23 1219 0 c1045 0 1223 -2 1247 -14z" />
      </g>
    </CopyIconSVG>
  );
};

export default CopyIcon;
