import React from 'react';

const PlayIcon = () => {
  return (
    <svg width="35" height="35" viewBox="3.5,4.5,24,24">
      <path d="M 11 9 L 24 16 L 11 23 z"></path>
    </svg>
  );
};

export default PlayIcon;
