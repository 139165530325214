import React, { createRef, useEffect } from 'react';
import {
  FirstPanel,
  SecondPanel,
  SplitDivider,
  SplitView,
} from '../../../styles/shared/SplitView';
// import FirstPanelComponent from './FirstPanel';

export interface SplitViewComponentProps {
  firstPanel: React.ReactElement; // could be left/ top element
  secondPanel: React.ReactElement; // could be right/ bottom element,
  collapsePanel?: 'first' | 'second' | undefined;
  orientation?: 'vertical' | 'horizontal';
}

// The current position of mouse
let x = 0;
let y = 0;

// height of second panel
let secondPanelHeight = 0;
let secondPanelWidth = 0;

const SplitViewComponent: React.FC<SplitViewComponentProps> = ({
  firstPanel,
  secondPanel,
  collapsePanel = undefined,
  orientation = 'vertical',
}) => {
  const firstPanelRef = createRef<HTMLDivElement>();
  const dividerRef = createRef<HTMLDivElement>();
  const secondPanelRef = createRef<HTMLDivElement>();

  const onMouseDown = (e: React.MouseEvent) => {
    if (!!collapsePanel) {
      return;
    }
    if (secondPanelRef.current) {
      // Get the current mouse position

      if (orientation === 'vertical') {
        y = e.clientY;
        secondPanelHeight = secondPanelRef.current.getBoundingClientRect()
          .height;
      } else {
        x = e.clientX;
        secondPanelWidth = secondPanelRef.current.getBoundingClientRect().width;
      }
      // Attach the listeners to `document`
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    if (dividerRef.current && firstPanelRef.current && secondPanelRef.current) {
      dividerRef.current.style.cursor = 'row-resize';
      document.body.style.cursor = 'row-resize';
      firstPanelRef.current.style.userSelect = 'none';
      firstPanelRef.current.style.pointerEvents = 'none';
      secondPanelRef.current.style.userSelect = 'none';
      secondPanelRef.current.style.pointerEvents = 'none';

      // How far the mouse has been moved

      if (orientation === 'vertical') {
        const dy = y - e.clientY;
        const newSecondPanelHeight = secondPanelHeight + dy;
        secondPanelRef.current.style.height = `${newSecondPanelHeight}px`;
      } else {
        dividerRef.current.style.cursor = 'col-resize';
        document.body.style.cursor = 'col-resize';
        const dx = x - e.clientX;
        const newSecondPanelWidth = secondPanelWidth + dx;
        secondPanelRef.current.style.width = `${newSecondPanelWidth}px`;
      }
    }
  };

  const onMouseUp = () => {
    if (dividerRef.current && firstPanelRef.current && secondPanelRef.current) {
      dividerRef.current.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      firstPanelRef.current.style.removeProperty('user-select');
      firstPanelRef.current.style.removeProperty('pointer-events');

      secondPanelRef.current.style.removeProperty('user-select');
      secondPanelRef.current.style.removeProperty('pointer-events');
    }

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    if (collapsePanel) {
      let collapsePanelRef =
        collapsePanel === 'first' ? firstPanelRef : secondPanelRef;
      if (collapsePanelRef.current) {
        collapsePanelRef.current.style.height = `0px`;
      }
    } else if (orientation === 'vertical') {
      secondPanelRef.current && (secondPanelRef.current.style.height = `40%`);
    }
  }, [collapsePanel]);

  return (
    <SplitView
      style={{ flexDirection: orientation === 'vertical' ? 'column' : 'row' }}
    >
      <FirstPanel
        style={orientation === 'vertical' ? {} : { minWidth: '20%', flex: '1' }}
        ref={firstPanelRef}
      >
        {firstPanel}
      </FirstPanel>
      {!collapsePanel && (
        <SplitDivider
          orientation={orientation}
          ref={dividerRef}
          onMouseDown={onMouseDown}
        />
      )}
      <SecondPanel
        ref={secondPanelRef}
        style={
          orientation === 'vertical'
            ? { height: '40%' }
            : { height: '100%', width: '60%', minWidth: '20%', zIndex: 1 }
        }
      >
        {secondPanel}
      </SecondPanel>
    </SplitView>
  );
};

export default SplitViewComponent;
