import React from 'react';

const AddTabIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      strokeWidth="4"
      viewBox="0 0 50 50"
      stroke="#dfdfdf"
    >
      <title>Opens a New Tab</title>
      <line x1="25" y1="13.1" x2="25" y2="36.9"></line>
      <line x1="36.9" y1="25" x2="13.1" y2="25"></line>
    </svg>
  );
};

export default AddTabIcon;
