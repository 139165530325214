import firebase from 'firebase/app';

import 'firebase/firestore';
import { FIRE_STORE_COLLECTION_NAME } from '../utils/constants';

const config = {
  apiKey: 'AIzaSyDpmLYrKQQwXt3LffEpZlALqsH8lelB94Y',
  authDomain: 'silicon-data-314510.firebaseapp.com',
  projectId: 'silicon-data-314510',
  storageBucket: 'silicon-data-314510.appspot.com',
  messagingSenderId: '545723991762',
  appId: '1:545723991762:web:719df1e982f51ef94acf0b',
  measurementId: 'G-S3V09CQCQN',
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();

export const fireStoreCollection = firestore.collection(
  FIRE_STORE_COLLECTION_NAME
);

export default firebase;

// util functions

export const addPlaygroundState = async (stateObject: Record<string, any>) => {
  try {
    const addedPlaygroundState = await fireStoreCollection.add({
      ...stateObject,
    });
    return addedPlaygroundState.id;
  } catch (error) {
    if (error instanceof Error) {
      console.log('Error\n', error + ': ' + error.message);
    }
    return '';
  }
};

export const getPlaygroundStateById = async (docId: string) => {
  const docSnapshot = await fireStoreCollection.doc(docId).get();
  return await docSnapshot.data();
};

export const updatePlaygroundState = async (
  docId: string,
  stateObject: Record<string, any>
) => {
  await fireStoreCollection.doc(docId).update(stateObject);
};
